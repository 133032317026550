// custom typefaces
import "typeface-lato"
import "typeface-amaranth"

// import "prismjs/themes/prism.css"

// Bootstrap
import 'bootstrap/dist/css/bootstrap.css'
// Holder-JS (used for Placeholder-images)
import 'holderjs'
// Font-Awesome
import '@fortawesome/fontawesome-free/css/all.css'
import './src/assets/styles/index.scss'
